<template>
  <section class="section__container">
    <div class="section__container_background">
      <img
        max-width="100%"
        height="100%"
        src="../../assets/conteudos_violacoes_direito_background_01.png"
        alt=""
      />

      <GoBack />
    </div>

    <div class="container position--relative">
      <section class="container">
        <v-row class="d-flex justify-center flex-column align-center">
          <div class="d-flex align-self-start pt-2 pb-2">
            <Breadcrumb
              class="breadcumb--item-color__scoped conteudos_tematicos_section_bc-color"
            >
              <template v-slot:breadcrumbLinks>
                <router-link
                  tag="p"
                  :to="{ name: link.href }"
                  v-for="(link, idx) in breadcrumbItems"
                  :key="idx"
                >
                  {{ link.text }}
                </router-link>
              </template>
            </Breadcrumb>
          </div>
          <v-col class="d-flex justify-center align-center">
            <img
              width="50px"
              src="../../assets/conteudos_violacoes_direito_title_engine.png"
              alt=""
            />
            <h3 class="ml-3">
              Desconsideração do cadastro Cáritas
            </h3>
          </v-col>
        </v-row>
      </section>
      <section class="container">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  O
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_cadastro' }"
                    >Cadastro</router-link
                  >
                  das perdas e danos das pessoas atingidas pelo rompimento da
                  barragem de Fundão, realizado pela Cáritas MG, é resultado de
                  ampla mobilização das pessoas atingidas, juntamente com as
                  instituições de justiça, em especial, o Ministério Público. Os
                  primeiros cadastros realizados no território eram aplicados
                  pelas próprias empresas causadoras dos danos e,
                  consequentemente, aquelas que deveriam arcar com as
                  indenizações a serem pagas no futuro. Assim, ficou evidente o
                  choque de interesses: de um lado, a realização do
                  cadastramento de cada atingido e o levantamento de seus danos
                  e, de outro, o pagamento desses danos a título de
                  indenizações.
                </p>
                <p>
                  Por óbvio, surgiram problemas e divergências, e as pessoas
                  atingidas começaram a questionar a imparcialidade das empresas
                  na condução do cadastramento. A mobilização foi tamanha que,
                  em sede judicial, ficou definido que a Assessoria Técnica
                  Independente (ATI) da Cáritas MG, escolhida pelas pessoas
                  atingidas, realizaria a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_contexto_conquistas' }"
                    >reformulação do Cadastro</router-link
                  >, e, em momento posterior, seria também a responsável por sua
                  aplicação. Desse modo, a garantia de um Cadastro não realizado
                  e conduzido pelas próprias empresas causadoras dos danos foi
                  uma conquista muito importante e inédita.
                </p>
                <p>
                  A partir daí, a ATI elaborou uma metodologia de levantamento
                  de danos ampla e dialogada, objetivando abarcar quatro eixos
                  temáticos: danos materiais; perda de atividade econômica e
                  renda; perda de bens coletivos; danos morais e perdas
                  imateriais. Tais eixos foram distribuídos entre um
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_reformulacao_cadastro' }"
                    >formulário</router-link
                  >
                  e os instrumentos a ele complementares:
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_cartografia_social_familiar' }"
                    >cartografia social</router-link
                  >,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_vistoria_advi' }"
                    >vistoria</router-link
                  >
                  e
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_tomadas_de_termo' }"
                    >tomada de termo</router-link
                  >. Por causa do comprometimento com a autodeclaração das
                  pessoas atingidas e identificação abrangente dos danos, o
                  Cadastro é o principal instrumento de levantamento das perdas
                  e danos sofridos em decorrência do rompimento da barragem de
                  Fundão.
                </p>
                <p>
                  Ao final do processo de cadastramento, as etapas que o compõem
                  são reunidas em um dossiê individualizado que é entregue para
                  cada núcleo familiar atingido e, posteriormente, enviado à
                  Fundação Renova. No Termo de Transação e Ajustamento de
                  Conduta (TTAC), celebrado em 2 de outubro de 2018, ficou
                  estabelecido que este
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_sistematizacao_dossie' }"
                    >dossiê</router-link
                  >
                  deveria ser a fonte preferencial para o reconhecimento e
                  indenização dos danos às pessoas atingidas que tenham optado
                  participar do Cadastro.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  A Fundação Renova, portanto, ao receber os dossiês deveria
                  elaborar propostas indenizatórias baseada neles, conforme se
                  extrai do TTAC, mas não é isso que tem acontecido na prática.
                  Na Fase de Negociação Extrajudicial (FNE), a Fundação Renova
                  apresenta propostas que deixam de reconhecer muitos dos danos
                  declarados durante o cadastramento, o que resulta em uma
                  imediata frustração do núcleo familiar ao se deparar com uma
                  proposta tão diferente das suas declarações.
                </p>
                <p>
                  Observa-se que uma das mais frequentes justificativas da
                  Fundação Renova, para a não inclusão dos danos declarados no
                  Cadastro em suas propostas, é a de que eles não são elegíveis
                  como danos indenizáveis dentro de sua política interna. Assim,
                  a FNE é conduzida pela Fundação Renova, através da aplicação
                  de uma política indenizatória composta por critérios escusos e
                  instáveis, que determinam quais danos levantados durante o
                  cadastramento serão de fato indenizados, em evidente desacordo
                  com o pactuado no TTAC de outubro de 2018.
                </p>
                <p>
                  Deve-se ponderar que o referido TTAC não estabeleceu os
                  limites de atuação da Fundação Renova no âmbito da FNE, não
                  fez previsão a procedimentos e nem critérios norteadores a
                  serem implementados nesse espaço de negociação extrajudicial.
                  É, então, valendo-se dessas lacunas que a Fundação Renova
                  admite, expressamente, aplicar critérios e políticas
                  indenizatórias por ela definidos unilateralmente e
                  implementados arbitrariamente na FNE.
                </p>
                <p>
                  É importante lembrar que tais critérios nunca foram
                  apresentados em sua integralidade pela Fundação Renova, apesar
                  de já terem sido feitas reiteradas solicitações formais para
                  que fossem publicizados. A Fundação tem exposto tais critérios
                  apenas de forma limitada a cada caso específico, pelo que
                  essas exposições visam somente fundamentar e sustentar a
                  proposta apresentada à determinada família.
                </p>
                <p>
                  Outra justificativa para o não reconhecimento de danos
                  declarados no Cadastro é a de que não foi verificada, por
                  parte da Fundação Renova, a verossimilhança entre as diversas
                  declarações constantes no dossiê, ou entre elas e outras
                  declarações dos atingidos e atingidas, como aquelas prestadas
                  no cadastro emergencial (conduzido exclusivamente pelas
                  empresas causadoras dos danos logo após o rompimento). De modo
                  que os danos autodeclarados são recorrentemente questionados e
                  colocados à prova, restando para as pessoas atingidas a
                  obrigação de prová-los.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  O que ocorre, portanto, é a desconsideração ampla e
                  sistemática dos dossiês produzidos pelo Cadastro conduzido
                  pela Cáritas MG, incluindo a desconsideração de toda a
                  metodologia de levantamento de perdas e danos construída pela
                  Assessoria Técnica Independente em parceria com as pessoas
                  atingidas. Ainda, ao colocar à prova as declarações dos
                  atingidos e atingidas, a Fundação Renova viola os princípios
                  da autodeclaração, da inversão do ônus da prova e da
                  interpretação mais benéfica em favor dos atingidos e
                  atingidas. Os princípios que deveriam dirigir todo o processo
                  indenizatório a fim de proteger a parte mais vulnerável, a
                  pessoa atingida, são colocados de lado, impossibilitando a
                  concretização da
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >.
                </p>
                <p>
                  A desconsideração do Cadastro é, além de tudo, a
                  desconsideração do tempo, do esforço e da saúde das pessoas
                  atingidas, que há seis anos revivem o momento traumático do
                  rompimento da barragem de Fundão, para descreverem as perdas e
                  os danos sofridos desde o desastre–crime e lutarem por uma
                  justa reparação, sendo submetidas a consecutivas violações de
                  direitos.
                </p>
                <p>
                  Convida-se à leitura do
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://www.google.com/url?q=http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf&sa=D&source=docs&ust=1653146093495057&usg=AOvVaw0U-VdAtCGHL5Bp8DqQcJmC"
                    class="pink--text"
                    >Parecer Técnico Jurídico</a
                  >
                  produzido pela equipe de Assessoria Jurídica da Cáritas MG, em
                  que foram relatadas, com mais detalhes, as práticas e
                  violações da Fundação Renova, no âmbito da FNE, perpetradas a
                  fim de negar a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >
                  dos danos decorrentes do rompimento da barragem de Fundão.
                </p>

                <p style="text-align: center;"></p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center pa-4 section__mb">
          <v-col cols="12" md="7" class="d-flex justify-center align-center">
            <v-btn
              :to="{ name: 'conteudos_tematicos_descumprimento_prazos' }"
              color="#EDBD43"
            >
              <strong>Conhecer "Descumprimento de Prazos"</strong></v-btn
            >
          </v-col>
        </v-row>
      </section>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Violações de Direito /",
          href: "conteudos_tematicos_violacoes_direitos",
        },

        {
          text: "Desconsideração do cadastro Cáritas",
          href: "conteudos_tematicos_desconsideracao_cadastro",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.v-btn {
  width: 80%;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
}
.section__container {
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

h3 {
  color: white;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    display: flex;
    justify-content: center;
  }
  h3 {
    color: black;
  }
}

@media (min-width: 1400px) {
  .section__container_background {
    display: flex;
    justify-content: center;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__mb {
  padding-bottom: 3em;
}

.breadcumb--item-color__scoped {
  color: white;
}
</style>
